/**
 * Nexus Dev Mode
 */
(() => {
  const searchParams = new URLSearchParams(window.location.search);
  if (!searchParams.has('devmode') && !sessionStorage.getItem('devmode')) return;

  sessionStorage.setItem('devmode', 'true');

  let ele = document.createElement('div');
  ele.innerHTML = /* html */ `
    <button
      id="devmode"
      class="btn btn-danger"
      type="button"
      style="position: fixed; left: 20px; bottom: 20px; margin: 0; z-index: 100;">
      Turn off Dev Mode
    </button>
  `;
  ele = ele.firstElementChild;

  document.documentElement.append(ele);

  ele.addEventListener('click', () => {
    sessionStorage.removeItem('devmode');
    searchParams.delete('devmode');
    window.location = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
  });

  // Load Dev CSS
  document.addEventListener('DOMContentLoaded', () => {
    const sheet = document.querySelector('link[href*="nexustheme.css"]');
    if (!sheet) return;

    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = `${sheet.href.replace('prod', 'dev')}?start=${new Date() * 1}`;

    sheet.before(link);

    // Give time for new CSS to load.
    setTimeout(() => {
      sheet.remove();
    }, 1000);
  });

  // Load Dev JS
  document.addEventListener('beforescriptexecute', (script) => {
    if (!script.target.src.includes('nexustheme')
    || script.target.src.includes('cdn.design.estechgroup.io/dev')
    || script.target.src.includes('cdn.design.estechgroup.io/prod')) {
      return;
    }

    const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XmlHttp');
    xhr.open('GET', script.target.src.replace('prod', 'dev'), false);
    xhr.send();

    if (xhr.status === 404) return;

    const link = document.createElement('script');
    link.type = 'text/javascript';
    link.async = false;
    link.textContent = xhr.responseText;

    script.preventDefault();
    script.stopPropagation();

    script.target.before(link);
    script.target.remove();
  });
})();
